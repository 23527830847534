<template>
  <v-app id="app" class="d-flex">
    <v-container
      fluid
      :class="{ 'd-flex': $vuetify.breakpoint.mdAndUp }"
      class="pa-0 ma-0"
      style="width: 100%"
    >
      <MobileNavbar
        v-if="!$vuetify.breakpoint.mdAndUp && routeCheck"
        :key="sidebarMobileKey"
      />
      <Sidebar
        v-if="$vuetify.breakpoint.mdAndUp && routeCheck"
        :key="sidebarMobileKey"
      />

      <v-sheet :class="[!liveShareRoute ? 'content' : 'liveShare']">
        <router-view />
      </v-sheet>
      <Loader v-if="showLoader" />
    </v-container>
  </v-app>
</template>

<script>
import Sidebar from './components/Navbar/Sidebar.vue'
import MobileNavbar from './components/Navbar/MobileNavbar.vue'
import Loader from './components/Loader'
import loader from './components/Livetracking/use/loader'
import ApiService from '@/services/api.service'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
import featuresSettings from '@/components/Administration/CompanySettings/Features/use/features'
export default {
  name: 'App',
  components: {
    MobileNavbar,
    Sidebar,
    Loader
  },
  setup() {
    const { showLoader } = loader()
    const { getLanguage, getChildCompanySettings, getToken, getSupportToken } =
      getCompanySettingsFromToken()
    const { features } = featuresSettings()
    return {
      showLoader,
      getLanguage,
      getChildCompanySettings,
      getToken,
      getSupportToken,
      features
    }
  },
  data() {
    return {
      sidebarMobileKey: 0
    }
  },
  computed: {
    routeCheck() {
      return (
        this.$route.path !== '/' &&
        this.$route.path !== '/resetPassword' &&
        !this.liveShareRoute
      )
    },
    liveShareRoute() {
      return this.$route.name === 'LiveShare'
    }
  },
  watch: {
    features() {
      this.sidebarMobileKey++
    }
  },
  created() {
    if (this.getLanguage) {
      this.$i18n.locale = this.getLanguage
    } else {
      this.$i18n.locale = 'en'
    }
    if (this.getToken) {
      ApiService.setHeader()
    }
    if (this.getChildCompanySettings) {
      ApiService.setChildCompanyAccessToken()
    }
    if (this.getSupportToken) {
      ApiService.setSupportAccessToken()
    }
  }
}
</script>
<style lang="postcss" scoped>
.sidebar-content {
  display: flex;
  height: 100%;
}
.content {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.liveShare {
  height: 100vh;
  width: 100% !important;
  overflow-y: auto;
}

@media (max-width: 960px) {
  .content {
    height: calc(100vh - 64px) !important;
  }
}
</style>
