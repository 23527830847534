import router from '@/router'
import { ref } from '@vue/composition-api'
import startAndEndDateTime from '@/components/use/startAndEndDateTime'

const { dateRange } = startAndEndDateTime()
export default function tablePaging() {
  const tableOptions = ref({
    page: Number(router.currentRoute.query.offset) + 1,
    itemsPerPage: 10
  })
  const routeQuery = ref({
    from: '',
    to: '',
    offset: '0',
    limit: '10',
    term: '',
    sortOrder: 'Ascending',
    sortField: 'id'
  })

  const routeQueryForDevicesReceivedData = ref({
    imei: 0,
    from: new Date(dateRange.value.start).toISOString(),
    to: new Date(dateRange.value.end).toISOString(),
    offset: '0',
    limit: '10',
    deviceMessageType: '',
    hasErrors: '',
    hasCanMessage: '',
    hasMobileEyeMessage: ''
  })
  const serverItemsLength = ref(0)

  const createSearchQuery = () => {
    return JSON.stringify(router.currentRoute.query)
      .replaceAll(/{|}|"/g, '')
      .split(',')
      .map(item => item.replace(':', '='))
      .join()
      .replaceAll(',', '&')
  }
  const updateDateRange = date => {
    if (new Date(date[1]) < new Date(date[0])) {
      let dateFrom = new Date(date[0]).setUTCHours(22, 59, 59)
      let dateTo = new Date(date[1]).setUTCHours(0, 0, 0)
      router.push({
        query: {
          ...router.currentRoute.query,
          from: new Date(dateTo).toISOString(),
          to: new Date(dateFrom).toISOString()
        }
      })
    } else {
      let dateFrom = new Date(date[0]).setUTCHours(0, 0, 0)
      let dateTo = new Date(date[1]).setUTCHours(22, 59, 59)
      router.push({
        query: {
          ...router.currentRoute.query,
          from: new Date(dateFrom).toISOString(),
          to: new Date(dateTo).toISOString()
        }
      })
    }
  }
  const updateServerItemsLength = val => {
    serverItemsLength.value = val
  }
  const updateTableOptions = options => {
    router.push({
      query: {
        ...router.currentRoute.query,
        offset: JSON.stringify(options.page - 1),
        limit: JSON.stringify(options.itemsPerPage),
        sortField: options.sortBy[0] ? options.sortBy[0] : 'id',
        sortOrder: options.sortDesc[0] ? 'Descending' : 'Ascending'
      }
    })
  }

  return {
    tableOptions,
    routeQuery,
    routeQueryForDevicesReceivedData,
    updateTableOptions,
    createSearchQuery,
    updateDateRange,
    serverItemsLength,
    updateServerItemsLength
  }
}
