import { ref, computed } from '@vue/composition-api'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
import cloneDeep from 'lodash-es/cloneDeep'

const { getUserRoles } = getCompanySettingsFromToken()
const features = ref({
  basicTracking: true,
  wayBillNotifications: false,
  wayBillNotificationSettings: {
    notificationFrequency: '',
    notificationEnabled: true,
    notificationEmail: '',
    violationNotificationEmail: '',
    notificationPhone: '',
    averageSpeed: 0,
    deviationThreshold: 0,
    clientNotificationEnabled: true,
    clientNotificationEmail: ''
  },
  wayBillProgress: false,
  wasteManagement: false,
  eld: false,
  engineDiagnostics: false,
  retentionTimeAtBuyerReport: false,
  mileageReport: false,
  geoFenceEntryExitReport: false,
  deviceActivityReport: false,
  mileageInOutReport: false,
  assetLocationReport: false,
  geoHistoryReport: false,
  geoFencesServedContainerReport: false
})
export default function featuresSettings() {
  const initialFeatures = cloneDeep(features)
  const isSupportUserOrIsReseller = computed(() => {
    return (
      getUserRoles.value.includes('Support') ||
      getUserRoles.value.includes('Reseller')
    )
  })
  const updateFeatures = val => {
    features.value = val
  }
  const updateFeaturesWayBillNotificationSettings = val => {
    features.value.wayBillNotificationSettings = val
  }
  const updateBasicTracking = val => {
    features.value.basicTracking = val
  }
  const updateWaybillsNotifications = val => {
    features.value.wayBillNotifications = val
  }
  const updateWaybillsProgress = val => {
    features.value.wayBillProgress = val
  }
  const updateWasteManagement = val => {
    features.value.wasteManagement = val
  }
  const updateEld = val => {
    features.value.eld = val
  }
  const updateEngineDiagnostics = val => {
    features.value.engineDiagnostics = val
  }
  const updateRetentionTimeAtBuyerReport = val => {
    features.value.retentionTimeAtBuyerReport = val
  }
  const updateMileageReport = val => {
    features.value.mileageReport = val
  }
  const updateGeoFenceEntryExitReport = val => {
    features.value.geoFenceEntryExitReport = val
  }
  const updateDeviceActivityReport = val => {
    features.value.deviceActivityReport = val
  }
  const updateMileageInOutReport = val => {
    features.value.mileageInOutReport = val
  }
  const updateAssetLocationReport = val => {
    features.value.assetLocationReport = val
  }
  const updateGeoHistoryReport = val => {
    features.value.geoHistoryReport = val
  }
  return {
    features,
    initialFeatures,
    isSupportUserOrIsReseller,
    updateFeatures,
    updateFeaturesWayBillNotificationSettings,
    updateBasicTracking,
    updateWaybillsNotifications,
    updateWaybillsProgress,
    updateWasteManagement,
    updateEld,
    updateEngineDiagnostics,
    updateRetentionTimeAtBuyerReport,
    updateMileageReport,
    updateGeoFenceEntryExitReport,
    updateDeviceActivityReport,
    updateMileageInOutReport,
    updateAssetLocationReport,
    updateGeoHistoryReport
  }
}
