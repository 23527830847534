import Vue from 'vue'
const toastService = {
  toastrError(err) {
    if (err && err.message) {
      Vue.$toast.error(err.message)
    }
  },
  toastrSuccess(success) {
    Vue.$toast.success(success.message)
  }
}

export default toastService
