import { TokenService } from '@/services/storage.service'
import { computed } from '@vue/composition-api'

export default function getCompanySettingsFromToken() {
  const getCompanySettings = computed(() => {
    return TokenService.getCompanySettings()
  })
  const getChildCompanySettings = computed(() => {
    return TokenService.getCurrentChildCompany()
  })
  const getLanguage = computed(() => {
    return TokenService.getLanguage()
  })
  const getUsername = computed(() => {
    return TokenService.getUsername()
  })
  const getToken = computed(() => {
    return TokenService.getToken()
  })
  const getSupportToken = computed(() => {
    return TokenService.getSupportToken()
  })
  const getUserId = computed(() => {
    return TokenService.getUserId()
  })
  const getUserRoles = computed(() => {
    return TokenService.getUserRole()
  })
  const getSupportTokenDetails = computed(() => {
    return TokenService.getSupportTokenDetails()
  })
  const getChildCompanyFeatures = computed(() => {
    return TokenService.getChildCompanyFeatures()
  })
  const getOwnCompanyFeatures = computed(() => {
    return TokenService.getCompanyFeatures()
  })
  const getMeasurementUnit = computed(() => {
    return getChildCompanySettings.value
      ? getChildCompanySettings.value.measurementUnit
      : getCompanySettings.value.measurementUnit
  })

  const getFuelUnit = computed(() => {
    return getChildCompanySettings.value
      ? getChildCompanySettings.value.fuelMeasurementUnit
      : getCompanySettings.value.fuelMeasurementUnit
  })

  const getMapType = computed(() => {
    return getChildCompanySettings.value
      ? getChildCompanySettings.value.mapType
      : getCompanySettings.value.mapType
  })

  const getAverageSpeed = computed(() => {
    return getChildCompanySettings.value
      ? getChildCompanySettings.value.averageSpeed
      : getCompanySettings.value.averageSpeed
  })

  const getCompanyId = computed(() => {
    return getChildCompanySettings.value
      ? getChildCompanySettings.value.id
      : getCompanySettings.value.companyId
  })

  const getTokenService = computed(() => {
    return getChildCompanySettings.value
      ? getChildCompanySettings.value
      : getCompanySettings.value
  })

  const getCompanyFeatures = computed(() => {
    return TokenService.getChildCompanyFeatures()
      ? TokenService.getChildCompanyFeatures()
      : TokenService.getCompanyFeatures()
  })
  return {
    getCompanySettings,
    getChildCompanySettings,
    getLanguage,
    getToken,
    getUserId,
    getUsername,
    getUserRoles,
    getSupportToken,
    getMeasurementUnit,
    getFuelUnit,
    getMapType,
    getAverageSpeed,
    getSupportTokenDetails,
    getTokenService,
    getCompanyId,
    getCompanyFeatures,
    getChildCompanyFeatures,
    getOwnCompanyFeatures
  }
}
