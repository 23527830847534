const TokenService = {
  getToken() {
    return localStorage.getItem('access_token')
  },
  setToken(accessToken) {
    localStorage.setItem('access_token', accessToken)
  },
  removeToken() {
    localStorage.removeItem('access_token')
  },
  getSupportToken() {
    return localStorage.getItem('support_access_token')
  },
  setSupportToken(supportAccessToken) {
    localStorage.setItem('support_access_token', supportAccessToken)
  },
  removeSupportToken() {
    localStorage.removeItem('support_access_token')
  },
  getSupportTokenDetails() {
    return JSON.parse(localStorage.getItem('support_token_details'))
  },
  setSupportTokenDetails(supportTokenDetails) {
    localStorage.setItem(
      'support_token_details',
      JSON.stringify(supportTokenDetails)
    )
  },
  removeSupportTokenDetails() {
    localStorage.removeItem('support_token_details')
  },
  getUserRole() {
    return localStorage.getItem('user_role')
  },
  setUserRole(roles) {
    localStorage.setItem('user_role', roles)
  },
  removeUserRole() {
    localStorage.removeItem('user_role')
  },

  getCorrelationId() {
    return localStorage.getItem('x-correlation-id')
  },
  setCorrelationId(id) {
    localStorage.setItem('x-correlation-id', id)
  },
  removeCorrelationId() {
    localStorage.removeItem('x-correlation-id')
  },
  setUserId(id) {
    localStorage.setItem('userId', id)
  },
  getUserId() {
    return localStorage.getItem('userId')
  },
  removeUserId() {
    localStorage.removeItem('userId')
  },
  setCompanySettings(companySettings) {
    localStorage.setItem('company_settings', JSON.stringify(companySettings))
  },
  getCompanySettings() {
    return localStorage.getItem('company_settings')
      ? JSON.parse(localStorage.getItem('company_settings'))
      : null
  },
  removeCompanySettings() {
    localStorage.removeItem('company_settings')
  },
  setCompanyFeatures(companyFeatures) {
    localStorage.setItem('company_features', JSON.stringify(companyFeatures))
  },
  getCompanyFeatures() {
    return localStorage.getItem('company_features')
      ? JSON.parse(localStorage.getItem('company_features'))
      : null
  },
  removeCompanyFeatures() {
    localStorage.removeItem('company_features')
  },
  setChildCompanyFeatures(childCompanyFeatures) {
    localStorage.setItem(
      'child_company_features',
      JSON.stringify(childCompanyFeatures)
    )
  },
  getChildCompanyFeatures() {
    return localStorage.getItem('child_company_features')
      ? JSON.parse(localStorage.getItem('child_company_features'))
      : null
  },
  removeChildCompanyFeatures() {
    localStorage.removeItem('child_company_features')
  },
  setCurrentChildCompany(childCompany) {
    localStorage.setItem('current_child_company', JSON.stringify(childCompany))
  },
  getCurrentChildCompany() {
    return localStorage.getItem('current_child_company')
      ? JSON.parse(localStorage.getItem('current_child_company'))
      : null
  },
  removeCurrentChildCompany() {
    localStorage.removeItem('current_child_company')
  },
  setUsername(username) {
    localStorage.setItem('username', username)
  },
  getUsername() {
    return localStorage.getItem('username')
  },
  removeUsername() {
    localStorage.removeItem('username')
  },
  getLanguage() {
    return localStorage.getItem('language')
  },
  setLanguage(lang) {
    localStorage.setItem('language', lang)
  },
  removeLanguage() {
    localStorage.removeItem('language')
  }
}

export { TokenService }
