import useGoogleMapsApi from '@/components/Livetracking/use/useGoogleMapsApi'
import { iconsPaths, iconsColors } from '@/helpers/assetsIcons'

const { getGoogleMapsApi } = useGoogleMapsApi()

export class MovingMarker extends getGoogleMapsApi.value.maps.Marker {
  isZooming = false
  timeToNextDest = 100
  startedAt = null
  destinations = []
  isMoving = false
  timeInPause = 0
  popUp = new getGoogleMapsApi.value.maps.InfoWindow()

  moveTo = (latLng, miliseconds, state) => {
    this.destinations = [...this.destinations, { latLng, miliseconds, state }]
    if (!this.isMoving) {
      this.step()
      setTimeout(() => {
        requestAnimationFrame(this.setCurrentLatLng.bind(this))
      }, 50)
    }
  }

  step() {
    if (this.nextLatLng) {
      this.startLatLng = this.nextLatLng
    }
    this.currentState = this.nextState

    this.isMoving = false
    const nextDestination = this.destinations.shift()

    let icon = {
      path: iconsPaths[this.currentState.state],
      fillColor: iconsColors[this.currentState.state],
      fillOpacity: 1,
      strokeWeight: 2,
      strokeColor: 'white',
      scale: 1,
      rotation: this.currentState.direction
    }
    if (nextDestination) {
      this.timeInPause = window.performance.now()
      this.startedAt = window.performance.now()
      this.nextLatLng = new getGoogleMapsApi.value.maps.LatLng(
        nextDestination.latLng.lat,
        nextDestination.latLng.lng
      )
      this.timeToNextDest = nextDestination.miliseconds
      this.nextState = nextDestination.state
      icon.rotation = nextDestination.state.direction
      icon.path = iconsPaths[nextDestination.state.state]
      icon.fillColor = iconsColors[nextDestination.state.state]
    }
    icon.path =
      this.currentState.type === 45 || this.currentState.type === 46
        ? iconsPaths[4]
        : icon.path
    icon.fillColor =
      this.currentState.type === 45 || this.currentState.type === 46
        ? iconsPaths[4]
        : icon.fillColor

    this.setIcon(icon)
  }

  jumpToLastDestination() {
    const nextDestinations = this.destinations.slice(
      this.destinations.length - 2,
      this.destinations.length
    )
    if (nextDestinations[0]?.latLng) {
      this.nextLatLng = new getGoogleMapsApi.value.maps.LatLng(
        nextDestinations[0].latLng.lat,
        nextDestinations[0].latLng.lng
      )
    }
    if (nextDestinations[1]) {
      this.destinations = [nextDestinations[1]]
    }
    this.step()
    setTimeout(() => {
      requestAnimationFrame(this.setCurrentLatLng.bind(this))
    }, 50)
  }

  setCurrentLatLng() {
    const now = window.performance.now()
    let time = now - this.timeInPause
    if (time > 3000) {
      this.jumpToLastDestination()
    }
    this.timeInPause = now
    const end = this.startedAt + this.timeToNextDest
    if (now < end && this.getDistance(this.startLatLng, this.nextLatLng) > 0) {
      this.isMoving = true
      setTimeout(() => {
        requestAnimationFrame(this.setCurrentLatLng.bind(this))
      }, 50)
    } else {
      if (this.destinations.length) {
        this.step()
        setTimeout(() => {
          requestAnimationFrame(this.setCurrentLatLng.bind(this))
        }, 50)
      } else {
        this.isMoving = false
        this.currentState = this.nextState
        let state =
          this.currentState.type === 45 || this.currentState.type === 46
            ? 4
            : this.currentState.state
        let icon = {
          path: iconsPaths[state],
          fillColor: iconsColors[state],
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: 'white',
          scale: 1,
          rotation: this.currentState.direction
        }
        this.setIcon(icon)
      }
    }
    if (!this.isZooming) {
      const t = now - this.startedAt
      const lat =
        this.startLatLng.lat() +
        ((this.nextLatLng.lat() - this.startLatLng.lat()) /
          this.timeToNextDest) *
          t
      const lng =
        this.startLatLng.lng() +
        ((this.nextLatLng.lng() - this.startLatLng.lng()) /
          this.timeToNextDest) *
          t
      const currentLatLng = new getGoogleMapsApi.value.maps.LatLng(lat, lng)

      this.setPosition(currentLatLng)
      getGoogleMapsApi.value.maps.event.trigger(
        this,
        'assetMoving',
        currentLatLng
      )
    }
  }

  rad = x => {
    return (x * Math.PI) / 180
  }

  getDistance = (p1, p2) => {
    let R = 6378137 // Earth’s mean radius in meter
    let dLat = this.rad(p2.lat() - p1.lat())
    let dLong = this.rad(p2.lng() - p1.lng())
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.lat())) *
        Math.cos(this.rad(p2.lat())) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2)
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    let d = R * c
    return d // returns the distance in meter
  }
}
