<template>
  <div
    class="d-flex flex-column"
    style="background-color: white; overflow-y: auto"
  >
    <v-list v-if="translations">
      <v-list-item class="px-10" :to="{ name: 'userProfile' }">
        <v-list-item-icon class="mr-2">
          <v-icon> mdi-account </v-icon>
        </v-list-item-icon>
        <v-list-item-title class="font-weight-medium text-caption">{{
          $t('userProfile.UserProfile')
        }}</v-list-item-title>
      </v-list-item>
      <v-list-group v-if="!miniVariant" class="translations">
        <template #activator>
          <v-list-item class="px-10">
            <v-list-item-title class="font-weight-medium text-caption">{{
              $t('global.Language')
            }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item-group v-model="language">
          <v-list-item
            v-for="(item, i) in translationsList"
            :key="i"
            class="ml-5"
            @click="onClick(item.value)"
          >
            <v-list-item-title class="font-weight-medium text-caption">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list-group>
      <v-menu
        v-else
        content-class="translationsMenu"
        offset-x
        transition="scale-transition"
      >
        <template #activator="{ on }">
          <v-list-item class="d-flex justify-space-between px-10" v-on="on">
            <v-list-item-title class="font-weight-medium text-caption">{{
              $t('global.Language')
            }}</v-list-item-title>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item>
        </template>
        <v-list-item
          v-for="(item, i) in translationsList"
          :key="i"
          class="ma-2"
          @click="onClick(item.value)"
        >
          <v-list-item-title class="font-weight-medium text-caption">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-menu>
      <v-list-item
        v-if="translations && supportingUser"
        class="px-10 font-weight-medium text-caption"
        @click="logOutAsCustomer"
      >
        <v-list-item-title class="font-weight-medium text-caption">
          {{ `${$t('menu.SignOutAs')} ${supportingUser.userName}` }}
        </v-list-item-title>
        <v-list-item-icon> <v-icon>logout</v-icon> </v-list-item-icon>
      </v-list-item>
      <v-list-item
        v-if="user && !supportingUser"
        class="font-weight-medium text-caption px-10"
        @click="logOut"
      >
        <v-list-item-title class="font-weight-medium text-caption">
          {{ logout.name }}
        </v-list-item-title>
        <v-list-item-icon> <v-icon>logout</v-icon> </v-list-item-icon>
      </v-list-item>
    </v-list>
    <template v-for="(item, i) in menuItems" v-else>
      <v-list-item
        v-if="item.enabled"
        :key="i"
        class="px-10 font-weight-medium text-caption"
        exact
        :to="item.to"
      >
        {{ item.name }}
      </v-list-item>
    </template>
    <v-list-item
      v-if="supportPannel"
      class="px-10 font-weight-medium text-caption"
      :to="pinLoginRoute"
    >
      {{ $t('supportPannel.PinLogin') }}
    </v-list-item>
  </div>
</template>
<script>
import { TokenService } from '@/services/storage.service'
import useAssets from '@/components/Livetracking/use/useAssets'
import supportingUserData from '@/components/SupportPannel/use/supportingUser'
import ApiService from '@/services/api.service'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
export default {
  name: 'Dropdown',
  props: {
    menuItems: {
      type: Array,
      default: () => []
    },
    translations: {
      type: Boolean,
      default: false
    },
    supportPannel: {
      type: Boolean,
      default: false
    },
    miniVariant: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { initAssets, removeAllAssets } = useAssets()
    const { supportingUser, updateSupportingUsernameAndRoles } =
      supportingUserData()
    const { getUsername, getLanguage } = getCompanySettingsFromToken()
    return {
      initAssets,
      removeAllAssets,
      supportingUser,
      updateSupportingUsernameAndRoles,
      getUsername,
      getLanguage
    }
  },
  data() {
    return {
      language: null,
      user: null
    }
  },
  computed: {
    translationsList() {
      return [
        { name: this.$t('menu.English'), value: 'en' },
        { name: this.$t('menu.Macedonian'), value: 'mk' },
        { name: this.$t('menu.Serbian'), value: 'rs' }
      ]
    },
    logout() {
      return {
        name: this.$t('menu.LogOut')
      }
    },
    pinLoginRoute() {
      return '/SupportPannel/SupportPinLogin/'
    }
  },
  created() {
    this.user = this.getUsername || ''
    this.language = this.translationsList.findIndex(
      x => x.value === (this.getLanguage || this.$i18n.locale)
    )
  },
  methods: {
    changeLanguage(lang) {
      if (this.getLanguage !== lang) {
        TokenService.setLanguage(lang)
        this.$i18n.locale = lang
      }
    },
    logOut() {
      TokenService.removeToken()
      TokenService.removeSupportToken()
      TokenService.removeSupportTokenDetails()
      TokenService.removeUserId()
      TokenService.removeCompanySettings()
      TokenService.removeCurrentChildCompany()
      TokenService.removeUsername()
      TokenService.removeUserRole()
      TokenService.removeCorrelationId()
      TokenService.removeCompanyFeatures()
      TokenService.removeChildCompanyFeatures()
      this.updateSupportingUsernameAndRoles(null)
      this.removeAllAssets()
      this.$router.push('/')
    },
    logOutAsCustomer() {
      this.removeAllAssets()
      TokenService.removeSupportToken()
      TokenService.removeSupportTokenDetails()
      ApiService.setHeader()
      this.initAssets()
      this.updateSupportingUsernameAndRoles(null)
      this.$router.push('/')
    },
    onClick(language) {
      if (this.translations && language) {
        this.changeLanguage(language)
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
>>> .translations > .v-list-group__header {
  padding-left: 0;
}
.translationsMenu {
  background: white;
}
</style>
