import axios from 'axios'
import { TokenService } from './storage.service'
import router from '../router'
import toastService from '@/services/toast.service'
import { i18n } from '@/translations/i18n'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
const { getToken, getChildCompanySettings, getSupportToken } =
  getCompanySettingsFromToken()

const ApiService = {
  // Stores the 401 interceptor position so that it can be later ejected when needed
  _401interceptor: null,
  url: process.env.VUE_APP_WEB_API,

  init() {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.request.status === 401) {
          TokenService.removeToken()
          TokenService.removeSupportToken()
          TokenService.removeSupportTokenDetails()
          TokenService.removeUserId()
          TokenService.removeCompanySettings()
          TokenService.removeCurrentChildCompany()
          TokenService.removeUsername()
          TokenService.removeUserRole()
          TokenService.removeCorrelationId()
          TokenService.removeCompanyFeatures()
          router.push('/')
        }
        if (error.request.status === 404) {
          toastService.toastrError({
            message: error.message
          })
        } else {
          if (getToken.value) {
            toastService.toastrError({
              message: i18n.t(`errorCodes.${error.response.data.errorCode}`)
            })
          }
        }
        throw error
      }
    )
  },

  setHeader() {
    axios.defaults.headers['Authorization'] = `Bearer ${getToken.value}`

    axios.defaults.headers['x-correlation-id'] = TokenService.getCorrelationId()
    axios.defaults.headers['enctype'] = 'multipart/form-data'
  },

  setSupportAccessToken() {
    axios.defaults.headers.Authorization = `Bearer ${getSupportToken.value}`
  },
  setLiveShareToken(token) {
    axios.defaults.headers.Authorization = `${token}`
  },

  setChildCompanyAccessToken() {
    axios.defaults.headers.Authorization = `Bearer ${getChildCompanySettings.value.token}`
  },

  removeHeader() {
    axios.defaults.headers = {}
  },

  get(resource) {
    return axios.get(this.url + resource)
  },

  post(resource, data) {
    return axios.post(this.url + resource, data)
  },

  postFile(resource, file) {
    let formData = new FormData()
    formData.append('imageFile', file)
    return axios.post(this.url + resource, formData)
  },

  put(resource, data) {
    return axios.put(this.url + resource, data)
  },

  delete(resource) {
    return axios.delete(this.url + resource)
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data)
  },

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use()
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor)
  }
}

export default ApiService

ApiService.init()
