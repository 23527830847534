import LivetrackingService from '@/services/livetracking/LivetrackingService'
import { ref } from '@vue/composition-api'
import L from 'leaflet'
import Vue from 'vue'
import loader from '@/components/Livetracking/use/loader'
import {
  assetIcon,
  iconsAnchor,
  iconsSize,
  iconsPaths,
  iconsColors
} from '@/helpers/assetsIcons'
import { toolTip } from '@/components/Livetracking/helpers/popUps'
import mapObjects from '@/components/Livetracking/use/mapObjects'
import router from '@/router'
import useGoogleMapsApi from '@/components/Livetracking/use/useGoogleMapsApi'
const { toggleLoader } = loader()
const assets = ref([])
const filteredAssets = ref([])
const assetsMarkers = ref(null)
const activeAssetId = ref(null)
const leafletMap = ref(null)
const googleMap = ref(null)
const googleMarkers = ref([])
const { updateIsMarkerCreated } = mapObjects()
const { getGoogleMapsApi } = useGoogleMapsApi()
export default function useAssets() {
  const iconsForAssetTypes = asset => {
    const typeVans = [2, 38, 39, 40, 41, 42, 43, 44]
    if (asset.type === 1) {
      asset.imagePath = require('@/images/icons/car-list.png')
    } else if (typeVans.includes(asset.type)) {
      asset.imagePath = require('@/images/icons/van-list.png')
    } else if (asset.type === 3) {
      asset.imagePath = require('@/images/icons/truck-list.png')
    } else if (asset.type === 4) {
      asset.imagePath = require('@/images/icons/trailer-list.png')
    } else if (asset.type === 5) {
      asset.imagePath = require('@/images/icons/yard-reader.png')
    } else if (asset.type === 6) {
      asset.imagePath = require('@/images/icons/dumper-list.png')
    } else if (asset.type === 7) {
      asset.imagePath = require('@/images/icons/van-list.png')
    } else if (asset.type === 8) {
      asset.imagePath = require('@/images/icons/truck-list.png')
    } else if (asset.type === 9) {
      asset.imagePath = require('@/images/icons/bus.png')
    } else if (asset.type === 45 || asset.type === 46) {
      asset.imagePath = require('@/images/icons/container.png')
    }
  }
  const initAssets = async () => {
    toggleLoader(true)
    try {
      const { data } = await LivetrackingService.getAllAssets()
      data.forEach(asset => {
        iconsForAssetTypes(asset)
      })
      assets.value = data
      filteredAssets.value = data
      if (googleMap.value) {
        getLeafletMap(null)
        createMarkersOnGoogleMaps(data, googleMap.value, googleMarkers.value)
      }
      if (leafletMap.value) {
        getGoogleMap(null)
        createMarkersOnLeaflet(data, leafletMap.value)
      }
      toggleLoader(false)
    } catch (error) {
      toggleLoader(false)
    }
  }
  const getLeafletMap = val => {
    leafletMap.value = val
  }
  const getGoogleMap = val => {
    googleMap.value = val
  }
  const createMarkersOnGoogleMaps = (val, map, markers) => {
    let {
      MovingMarker
    } = require('@/components/Livetracking/helpers/googleMovingMarker')
    let bounds = new getGoogleMapsApi.value.maps.LatLngBounds()
    val.map(asset => {
      let state = asset.state
      if (Math.abs(new Date() - new Date(asset.utcCreated)) > 4.32e7) {
        state = 8
      }
      state = asset.type === 45 || asset.type === 46 ? 7 : state
      state =
        String(asset.latitude).charAt(0) === '0' ||
        String(asset.longitude).charAt(0) === '0'
          ? 9
          : state
      let marker = new MovingMarker({
        position: new getGoogleMapsApi.value.maps.LatLng(
          asset.latitude,
          asset.longitude
        ),
        startLatLng: new getGoogleMapsApi.value.maps.LatLng(
          asset.latitude,
          asset.longitude
        ),
        map: map,
        icon: {
          path: iconsPaths[state],
          fillColor: iconsColors[state],
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: 'white',
          scale: 1,
          rotation: asset.direction
        },
        currentState: asset,
        nextState: asset,
        optimized: true
      })
      Vue.set(asset, 'marker', marker)
      markers.push(asset.marker)
      googleMarkers.value = markers
      bounds.extend({ lat: asset.latitude, lng: asset.longitude })
      marker.addListener('click', () => {
        openAsset(asset)
      })
      marker.addListener('mouseover', () => {
        asset.marker.popUp.setContent(toolTip(asset))
        asset.marker.popUp.open(map, marker)
      })
      marker.addListener('mouseout', () => {
        asset.marker.popUp.close()
      })
    })
    updateFilteredAssets(val)
    map.fitBounds(bounds)
    updateIsMarkerCreated(true)
  }
  const createMarkersOnLeaflet = (val, map) => {
    let bounds = []
    val.map(asset => {
      let state = asset.state
      if (Math.abs(new Date() - new Date(asset.utcCreated)) > 4.32e7) {
        state = 8
      }
      state = asset.type === 45 || asset.type === 46 ? 7 : state
      state =
        String(asset.latitude).charAt(0) === '0' ||
        String(asset.longitude).charAt(0) === '0'
          ? 9
          : state
      let marker = L.movingMarker(L.latLng(asset.latitude, asset.longitude), {
        icon: L.divIcon({
          iconSize: iconsSize[state],
          iconAnchor: iconsAnchor[state],
          className: '',
          html: assetIcon(state, asset.direction)
        }),
        currentState: asset,
        state: asset.state,
        assetId: asset.assetId,
        utcReceived: asset.lastUpdate,
        opacity: 1,
        popUp: L.popup(L.latLng(asset.latitude, asset.longitude), {
          direction: 'top',
          offset: [0, -25],
          closeButton: false
        })
      })
      Vue.set(asset, 'marker', marker)
      assetsMarkers.value.addLayer(asset.marker)
      bounds.push([asset.latitude, asset.longitude])
      marker.on('click', e => {
        if (e.target.options.opacity === 1) {
          openAsset(asset)
        }
      })
      marker.on('mouseover', e => {
        if (e.target.options.opacity === 1) {
          e.target.options.popUp
            .setLatLng(e.target.getLatLng())
            .setContent(toolTip(asset))
            .openOn(map)
        }
      })
      marker.on('mouseout', e => {
        if (e.target.options.opacity === 1) {
          e.target.options.popUp.close()
        }
      })
    })
    updateFilteredAssets(val)
    map.fitBounds(bounds)
    updateIsMarkerCreated(true)
  }
  const openAsset = asset => {
    const path = `/LiveTracking/vehiclesAndAssets/${asset.assetId}`
    router.push(path)
    setClickActive(asset.assetId, true)
  }
  const updateAssets = updatedAsset => {
    let foundAssetIndex = assets.value.findIndex(
      asset => asset.assetId === updatedAsset.assetId
    )

    let oldTime = new Date(assets.value[foundAssetIndex].utcCreated).getTime()
    let newTime = new Date(updatedAsset.utcCreated).getTime()
    let miliseconds = newTime - oldTime

    let newPosition = L.latLng(updatedAsset.latitude, updatedAsset.longitude)

    if (updatedAsset.distance < 0.05 || miliseconds <= 0) {
      miliseconds = 100
    }
    assets.value[foundAssetIndex]?.marker.moveTo(
      newPosition,
      miliseconds,
      updatedAsset
    )
    Vue.set(assets.value, foundAssetIndex, {
      ...assets.value[foundAssetIndex],
      ...updatedAsset
    })
    let filteredAssetIndex = filteredAssets.value.findIndex(
      asset => asset.assetId === updatedAsset.assetId
    )
    if (filteredAssetIndex >= 0) {
      Vue.set(filteredAssets.value, filteredAssetIndex, {
        ...filteredAssets.value[filteredAssetIndex],
        ...updatedAsset
      })
    }
  }
  const removeAllAssets = () => {
    assets.value = ref([])
    filteredAssets.value = ref([])
  }

  const updateFilteredAssets = value => {
    filteredAssets.value = value
  }

  const setClickActive = (assetId, value) => {
    filteredAssets.value = filteredAssets.value.map(asset => {
      if (asset.assetId === assetId) {
        return {
          ...asset,
          isClickActive: value
        }
      } else {
        return asset
      }
    })
  }

  const updateAssetMarkers = value => {
    assetsMarkers.value = value
  }
  const setAssetActive = assetId => {
    activeAssetId.value = assetId
  }

  return {
    initAssets,
    filteredAssets,
    updateFilteredAssets,
    updateAssets,
    assets,
    activeAssetId,
    setAssetActive,
    setClickActive,
    removeAllAssets,
    assetsMarkers,
    updateAssetMarkers,
    iconsForAssetTypes,
    createMarkersOnLeaflet,
    createMarkersOnGoogleMaps,
    getLeafletMap,
    getGoogleMap
  }
}
