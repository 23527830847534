<template>
  <v-sheet>
    <v-toolbar flat>
      <v-toolbar-title>
        <v-img max-height="70" max-width="70" src="@/images/LogoE2T.png" />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$router.push({ name: 'HelpComponent' })">
        <v-icon>help_outline</v-icon>
      </v-btn>
      <v-menu
        z-index="9999"
        offset-y
        transition="scale-transition"
        bottom
        :close-on-content-click="false"
      >
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>face</v-icon>
          </v-btn>
        </template>
        <DropdownMenu :translations="true" />
      </v-menu>
      <div>
        <h5
          class="font-weight-regular"
          :class="{
            'red--text': supportingUser
          }"
        >
          {{ `${username} (${companyName})` }}
        </h5>
        <div v-if="supportingUser" class="d-flex align-center red--text">
          <h6>{{ supportingUser.userName }}</h6>
          |
          <div
            v-for="(role, i) in supportingUser.userRoles"
            :key="i"
            style="font-size: 11px"
          >
            <div>{{ role }}</div>
          </div>
        </div>
      </div>
      <v-app-bar-nav-icon
        v-if="
          !isViewerUser || isLivetrackingUser || isUserAdmin || isSupportUser
        "
        @click.native.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" absolute>
      <v-list>
        <v-list-item class="pa-0">
          <v-list-item-title class="d-flex justify-end pr-4">
            <v-icon @click.native.stop="drawer = !drawer"> close </v-icon>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="basicTrackingFeature"
          :to="{ name: 'liveTrackingAssets' }"
        >
          <v-list-item-icon>
            <v-icon> mdi-car-traction-control </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            $t('liveTracking.LiveTracking')
          }}</v-list-item-title>
        </v-list-item>
        <v-list-group
          v-if="
            !isViewerUser || isLivetrackingUser || isUserAdmin || isSupportUser
          "
          prepend-icon="mdi-history"
        >
          <template #activator>
            <v-list-item-content>
              <v-list-item-title>{{ $t('global.History') }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <DropdownMenu :menu-items="historyDropdown" />
        </v-list-group>
        <v-list-group
          v-if="
            !isViewerUser || isLivetrackingUser || isUserAdmin || isSupportUser
          "
          prepend-icon="mdi-text-box"
        >
          <template #activator>
            <v-list-item-content>
              <v-list-item-title>{{ $t('reports.Reports') }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <DropdownMenu :menu-items="reportsDropdown" />
        </v-list-group>
        <v-list-group
          v-if="
            !isViewerUser || isLivetrackingUser || isUserAdmin || isSupportUser
          "
          prepend-icon="receipt_long"
        >
          <template #activator>
            <v-list-item-content>
              <v-list-item-title>{{ $t('global.WayBills') }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <DropdownMenu :menu-items="waybillsDropdown" />
        </v-list-group>
        <v-list-item
          v-if="
            (!isViewerUser && !isLivetrackingUser) ||
            isUserAdmin ||
            isSupportUser
          "
          :to="{ name: 'Routes' }"
        >
          <v-list-item-icon>
            <v-icon>mdi-routes</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('global.Routes') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="
            (!isViewerUser && !isLivetrackingUser) ||
            isUserAdmin ||
            isSupportUser
          "
          :to="{ name: 'Geofences', query: routeQuery }"
        >
          <v-list-item-icon>
            <v-icon> pentagon </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('geofences.Geofences') }}</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="
            (!isViewerUser && !isLivetrackingUser) ||
            isUserAdmin ||
            isSupportUser
          "
          :to="{ name: 'Documents', query: routeQuery }"
        >
          <v-list-item-icon>
            <v-icon> description </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('documents.Documents') }}</v-list-item-title>
        </v-list-item>

        <v-list-group
          v-if="
            (!isViewerUser && !isLivetrackingUser) ||
            isUserAdmin ||
            isSupportUser
          "
          prepend-icon="mdi-account-multiple"
        >
          <template #activator>
            <v-list-item-content>
              <v-list-item-title>{{
                $t('administration.Administration')
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <DropdownMenu :menu-items="administrationDropdown" />
        </v-list-group>
        <v-list-group
          v-if="isSupportUser && !supportingUser"
          prepend-icon="mdi-face-agent"
        >
          <template #activator>
            <v-list-item-content>
              <v-list-item-title>{{
                $t('supportPannel.SupportPannel')
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <DropdownMenu
            :menu-items="supportPannelDropdown"
            :support-pannel="true"
          />
        </v-list-group>
        <v-list-group
          v-if="isUserAdmin || isSupportUser"
          prepend-icon="mdi-cog"
        >
          <template #activator>
            <v-list-item-content>
              <v-list-item-title>{{ $t('global.Settings') }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list>
            <v-list-item
              :to="{ name: 'CompanySettings' }"
              class="font-weight-medium text-caption px-10"
            >
              {{ $t('companySettings.General') }}</v-list-item
            >
            <v-list-item
              :to="{ name: 'BasicTracking' }"
              class="font-weight-medium text-caption px-10"
            >
              {{ $t('companySettings.Features') }}
            </v-list-item>
          </v-list>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </v-sheet>
</template>
<script>
import DropdownMenu from './DropdownMenu.vue'
import menuDropdowns from './menuDropdowns.js'
import supportingUserData from '@/components/SupportPannel/use/supportingUser'
import updateSwitchedCompanyData from '@/components/SupportPannel/use/switchCompanies'
import tablePaging from '@/components/tablePaging'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
export default {
  name: 'Navbar',
  components: {
    DropdownMenu
  },
  setup() {
    const {
      administrationDropdown,
      reportsDropdown,
      historyDropdown,
      supportPannelDropdown,
      waybillsDropdown
    } = menuDropdowns()
    const { routeQuery } = tablePaging()
    const { supportingUser, updateSupportingUsernameAndRoles } =
      supportingUserData()
    const { updateSwitchedCompanyName, companyName } =
      updateSwitchedCompanyData()
    const {
      getCompanySettings,
      getUsername,
      getUserRoles,
      getSupportTokenDetails,
      getCompanyFeatures
    } = getCompanySettingsFromToken()
    return {
      administrationDropdown,
      reportsDropdown,
      historyDropdown,
      supportPannelDropdown,
      waybillsDropdown,
      supportingUser,
      updateSupportingUsernameAndRoles,
      updateSwitchedCompanyName,
      companyName,
      routeQuery,
      getCompanySettings,
      getUsername,
      getUserRoles,
      getSupportTokenDetails,
      getCompanyFeatures
    }
  },
  data() {
    return {
      userRole: '',
      drawer: false,
      companyId: '',
      username: ''
    }
  },
  computed: {
    isViewerUser() {
      return this.userRole?.includes('Viewer')
    },
    isLivetrackingUser() {
      return this.userRole?.includes('Livetracking')
    },
    isUserAdmin() {
      return this.userRole?.includes('Admin')
    },
    isSupportUser() {
      return this.userRole?.includes('Support')
    },
    basicTrackingFeature() {
      return this.getCompanyFeatures.basicTracking
    }
  },
  created() {
    this.userRole = this.getUserRoles || []
    this.companyId = this.getCompanySettings?.companyId
    this.username = this.getUsername
    if (this.getSupportTokenDetails) {
      this.updateSupportingUsernameAndRoles(this.getSupportTokenDetails)
    }
    this.updateSwitchedCompanyName()
  }
}
</script>
<style lang="postcss" scoped>
.v-navigation-drawer {
  z-index: 9999;
}
>>> .v-list-item__icon {
  margin-right: 12px !important;
  margin-left: 0px !important;
  min-width: 24px !important;
}
</style>
