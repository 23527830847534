export const iconsPaths = [
  'M 3 3 h 14 v 14 h -14 z',
  'M 3 3 h 14 v 14 h -14 z',
  'M 3 3 h 14 v 14 h -14 z',
  'M 3 3 h 14 v 14 h -14 z',
  'M 3 3 h 14 v 14 h -14 z',
  'M 8.5 1 L 15.5 12 L 2.5 12 L 8.5 1 Z M 2 15 h 14 v 14 h -14 z',
  'M 3 3 h 14 v 14 h -14 z',
  'M 6 0 L 14 0 L 21 4 L 14 14 L 7 14 L 0 4 z',
  'M 3 3 h 14 v 14 h -14 z',
  'M 3 3 h 14 v 14 h -14 z'
]

export const iconsColors = [
  '#F9A825',
  '#F9A825',
  '#F9A825',
  '#66bb6a',
  '#F9A825',
  '#66bb6a',
  '#F9A825',
  '#757575',
  '#757575',
  '#ef5350'
]

export const iconsSize = [
  [20, 20],
  [20, 20],
  [20, 20],
  [20, 20],
  [20, 20],
  [20, 32],
  [20, 20],
  [20, 20],
  [20, 20],
  [20, 20]
]

export const iconsAnchor = [
  [10, 20],
  [10, 20],
  [10, 20],
  [10, 20],
  [10, 20],
  [10, 32],
  [10, 20],
  [10, 20],
  [10, 20],
  [10, 20]
]

export const assetIcon = (state, direction) => {
  return `<svg
  width="${iconsSize[state][0]}"
  height="${iconsSize[state][1]}"
  style="rotate:z ${direction}deg"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    stroke-width="2px"
    stroke='#ffffff'
    fill=${iconsColors[state]}

    d="${iconsPaths[state]}"
  ></path>
</svg>`
}
