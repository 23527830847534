import { toolTip } from '@/components/Livetracking/helpers/popUps'
import mapObjects from '@/components/Livetracking/use/mapObjects'
import { i18n } from '@/translations/i18n'

const { getMapObject, getGoogleMapObject } = mapObjects()

export const assetOnLeaflet = asset => {
  getMapObject.value.fitBounds([asset?.marker?.getLatLng()])
  getMapObject.value.setZoom(15)
  asset.marker.options.popUp
    .setLatLng(asset.marker.getLatLng())
    .setContent(toolTip(asset))
    .openOn(getMapObject.value)
}

export const assetOnGoogleMap = asset => {
  getGoogleMapObject.value.setCenter(asset?.marker?.getPosition())
  getGoogleMapObject.value.setZoom(18)
  asset.marker.popUp.setContent(toolTip(asset))
  asset.marker.popUp.open(getGoogleMapObject.value, asset.marker)
}

export const getAssetState = asset => {
  if (asset?.state === 0) {
    return 'Undefined'
  } else if (asset?.state === 1) {
    return 'OnBattery'
  } else if (asset?.state === 2) {
    return 'Sleeping'
  } else if (asset?.state === 3) {
    return 'Parked'
  } else if (asset?.state === 4) {
    return 'Standby'
  } else if (asset?.state === 5) {
    return 'Driving'
  } else if (asset?.state === 6) {
    return 'Idle'
  }
}

export const getAssetStateValue = asset => {
  if (getAssetState(asset?.marker?.currentState) === 'Undefined') {
    return 'No State'
  } else if (getAssetState(asset?.marker?.currentState) === 'OnBattery') {
    return 'On Battery'
  } else if (getAssetState(asset?.marker?.currentState) === 'Sleeping') {
    return 'Sleeping'
  } else if (getAssetState(asset?.marker?.currentState) === 'Parked') {
    return i18n.t('assetsFilters.Parking')
  } else if (getAssetState(asset?.marker?.currentState) === 'Standby') {
    return 'Standby'
  } else if (getAssetState(asset?.marker?.currentState) === 'Driving') {
    return i18n.t('global.Driving')
  } else if (getAssetState(asset?.marker?.currentState) === 'Idle') {
    return i18n.t('global.Idle')
  } else {
    return getAssetState(asset?.marker?.currentState)
  }
}
