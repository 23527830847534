var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"mini-variant":_vm.miniVariant,"mobile-breakpoint":0,"mini-variant-width":"52","width":"300","height":"100vh"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-divider'),_c('v-list',{attrs:{"dense":""}},[(_vm.isSupportUser && !_vm.supportingUser)?[(_vm.miniVariant)?_c('v-menu',{attrs:{"z-index":"9999","offset-x":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-face-agent")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('supportPannel.SupportPannel')))])])]}}],null,false,3257639235)},[_c('DropdownMenu',{attrs:{"menu-items":_vm.supportPannelDropdown,"support-pannel":true}})],1):_c('v-list-group',{on:{"click":function($event){return _vm.closeDropdown('supportMenu')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v(" mdi-face-agent ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('supportPannel.SupportPannel')))])],1)]},proxy:true}],null,false,3666148042),model:{value:(_vm.supportMenu),callback:function ($$v) {_vm.supportMenu=$$v},expression:"supportMenu"}},[_c('DropdownMenu',{attrs:{"menu-items":_vm.supportPannelDropdown,"support-pannel":true}})],1)]:_vm._e(),(
          (!_vm.isViewerUser && !_vm.isLivetrackingUser) ||
          _vm.isUserAdmin ||
          _vm.isSupportUser
        )?[(_vm.miniVariant)?_c('v-menu',{attrs:{"z-index":"9999","offset-x":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
        var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('global.Settings')))])])]}}],null,false,2722620499)},[_c('v-list',[_c('v-list-item',{staticClass:"px-10 font-weight-medium text-caption",attrs:{"to":{ name: 'CompanySettings' }}},[_vm._v(" "+_vm._s(_vm.$t('companySettings.General'))+" ")]),_c('v-list-item',{staticClass:"px-10 font-weight-medium text-caption",attrs:{"to":{ name: 'BasicTracking' }}},[_vm._v(" "+_vm._s(_vm.$t('companySettings.Features'))+" ")])],1)],1):_c('v-list-group',{on:{"click":function($event){return _vm.closeDropdown('settingsMenu')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v(" mdi-cog ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('global.Settings'))+" ")])],1)]},proxy:true}],null,false,3781227706),model:{value:(_vm.settingsMenu),callback:function ($$v) {_vm.settingsMenu=$$v},expression:"settingsMenu"}},[_c('v-list',[_c('v-list-item',{staticClass:"px-10 font-weight-medium text-caption",attrs:{"to":{ name: 'CompanySettings' }}},[_vm._v(" "+_vm._s(_vm.$t('companySettings.General'))+" ")]),_c('v-list-item',{staticClass:"px-10 font-weight-medium text-caption",attrs:{"to":{ name: 'BasicTracking' }}},[_vm._v(" "+_vm._s(_vm.$t('companySettings.Features'))+" ")])],1)],1)]:_vm._e(),(_vm.miniVariant)?_c('v-menu',{attrs:{"z-index":"9999","top":"","offset-x":"","close-on-content-click":false,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
        var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('v-list-item-title',_vm._g(_vm._b({staticClass:"d-flex justify-center"},'v-list-item-title',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-avatar',{staticClass:"my-2",class:[
                    _vm.supportingUser ? 'red lighten-1 white--text' : 'accent'
                  ],attrs:{"size":"24"}},[_c('span',{staticClass:"font-weight-regular text-caption"},[_vm._v(_vm._s(_vm.username.slice(0, 2)))])])],1)]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm.username))]),(!_vm.supportingUser)?_c('span',{staticStyle:{"width":"100%","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v("("+_vm._s(_vm.companyName)+")")]):_vm._e(),(_vm.supportingUser)?_c('div',{staticClass:"d-flex",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.supportingUser.userName)+" | "),_vm._l((_vm.supportingUser.userRoles),function(role,i){return _c('div',{key:i},[_c('div',[_vm._v(_vm._s(role))])])})],2):_vm._e()])])]}}],null,false,841825455)},[_c('DropdownMenu',{attrs:{"translations":true,"mini-variant":_vm.miniVariant}})],1):_c('v-list-group',{on:{"click":function($event){return _vm.closeDropdown('userMenu')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"px-0",staticStyle:{"width":"70%"}},[_c('v-avatar',{staticClass:"mr-2",class:[
                _vm.supportingUser ? 'red lighten-1 white--text' : 'accent'
              ],attrs:{"size":"24"}},[_c('span',{staticClass:"font-weight-regular text-caption"},[_vm._v(_vm._s(_vm.username.slice(0, 2)))])]),_c('v-list-item-title',{staticClass:"d-flex flex-column",class:{
                supportingUser: 'red--text px-3 py-2 '
              }},[_c('span',[_vm._v(_vm._s(_vm.username))]),(!_vm.supportingUser)?_c('span',{staticStyle:{"width":"100%","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v("("+_vm._s(_vm.companyName)+")")]):_vm._e(),(_vm.supportingUser)?_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.supportingUser.userName)+" | "),_vm._l((_vm.supportingUser.userRoles),function(role,i){return _c('div',{key:i,staticStyle:{"font-size":"11px"}},[_c('div',[_vm._v(_vm._s(role))])])})],2):_vm._e()])],1)]},proxy:true}]),model:{value:(_vm.userMenu),callback:function ($$v) {_vm.userMenu=$$v},expression:"userMenu"}},[_c('DropdownMenu',{attrs:{"translations":true}})],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ name: 'HelpComponent' })}}},[_c('v-tooltip',{attrs:{"disabled":!_vm.miniVariant,"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-list-item-icon',_vm._g({class:[_vm.miniVariant ? 'mx-2' : 'mr-2']},on),[_c('v-icon',[_vm._v("help_outline")])],1),_c('v-list-item-content',[(!_vm.miniVariant)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('global.Help')))]):_vm._e()],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('global.Help')))])])],1),_c('v-list-item',{staticClass:"px-0"},[_c('v-btn',{staticClass:"px-0 d-flex justify-end",attrs:{"block":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSidebar.apply(null, arguments)}}},[(_vm.miniVariant)?_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-chevron-right")]):_vm._e(),(!_vm.miniVariant)?_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-chevron-left")]):_vm._e()],1)],1)],2)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"pb-0",attrs:{"dense":""}},[_c('v-list-item',{staticClass:"d-flex justify-center"},[_c('v-img',{attrs:{"max-height":"60","max-width":"60","src":require("@/images/LogoE2T.png")}})],1),_c('v-divider'),(_vm.basicTrackingFeature)?_c('v-list-item',{attrs:{"to":{ name: 'liveTrackingAssets' }}},[_c('v-tooltip',{attrs:{"disabled":!_vm.miniVariant,"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-list-item-icon',_vm._g({class:[_vm.miniVariant ? 'mx-2' : 'mr-2']},on),[_c('v-icon',[_vm._v(" mdi-car-traction-control ")])],1),_c('v-list-item-content',[(!_vm.miniVariant)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('liveTracking.LiveTracking')))]):_vm._e()],1)]}}],null,false,978888256)},[_c('span',[_vm._v(_vm._s(_vm.$t('liveTracking.LiveTracking')))])])],1):_vm._e(),(
        !_vm.isViewerUser || _vm.isLivetrackingUser || _vm.isUserAdmin || _vm.isSupportUser
      )?[(_vm.miniVariant)?_c('v-menu',{attrs:{"z-index":"9999","offset-x":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var menu = ref.on;
      var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var tooltip = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('global.History')))])])]}}],null,false,3460512189)},[_c('DropdownMenu',{attrs:{"menu-items":_vm.historyDropdown}})],1):_c('v-list-group',{on:{"click":function($event){return _vm.closeDropdown('historyMenu')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v(" mdi-history ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('global.History')))])],1)]},proxy:true}],null,false,1104305876),model:{value:(_vm.historyMenu),callback:function ($$v) {_vm.historyMenu=$$v},expression:"historyMenu"}},[_c('DropdownMenu',{attrs:{"menu-items":_vm.historyDropdown}})],1)]:_vm._e(),(
        !_vm.isViewerUser || _vm.isLivetrackingUser || _vm.isUserAdmin || _vm.isSupportUser
      )?[(_vm.miniVariant)?_c('v-menu',{attrs:{"z-index":"9999","offset-x":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var menu = ref.on;
      var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var tooltip = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-text-box")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('reports.Reports')))])])]}}],null,false,716993843)},[_c('DropdownMenu',{attrs:{"menu-items":_vm.reportsDropdown}})],1):_c('v-list-group',{on:{"click":function($event){return _vm.closeDropdown('reportsMenu')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v(" mdi-text-box ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('reports.Reports')))])],1)]},proxy:true}],null,false,3614166330),model:{value:(_vm.reportsMenu),callback:function ($$v) {_vm.reportsMenu=$$v},expression:"reportsMenu"}},[_c('DropdownMenu',{attrs:{"menu-items":_vm.reportsDropdown}})],1)]:_vm._e(),(
        !_vm.isViewerUser || _vm.isLivetrackingUser || _vm.isUserAdmin || _vm.isSupportUser
      )?[(_vm.miniVariant)?_c('v-menu',{attrs:{"z-index":"9999","offset-x":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var menu = ref.on;
      var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var tooltip = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("receipt_long")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('global.WayBills')))])])]}}],null,false,1346770158)},[_c('DropdownMenu',{attrs:{"menu-items":_vm.waybillsDropdown}})],1):_c('v-list-group',{on:{"click":function($event){return _vm.closeDropdown('waybillsMenu')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v(" receipt_long ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('global.WayBills')))])],1)]},proxy:true}],null,false,3091054023),model:{value:(_vm.waybillsMenu),callback:function ($$v) {_vm.waybillsMenu=$$v},expression:"waybillsMenu"}},[_c('DropdownMenu',{attrs:{"menu-items":_vm.waybillsDropdown}})],1)]:_vm._e(),(
        (!_vm.isViewerUser && !_vm.isLivetrackingUser) || _vm.isUserAdmin || _vm.isSupportUser
      )?[_c('v-list-item',{attrs:{"to":{ name: 'Routes', query: _vm.routeQuery }}},[_c('v-tooltip',{attrs:{"disabled":!_vm.miniVariant,"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-list-item-icon',_vm._g({class:[_vm.miniVariant ? 'mx-2' : 'mr-2']},on),[_c('v-icon',[_vm._v("mdi-routes")])],1),_c('v-list-item-content',[(!_vm.miniVariant)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('global.Routes')))]):_vm._e()],1)]}}],null,false,2368674052)},[_c('span',[_vm._v(_vm._s(_vm.$t('global.Routes')))])])],1)]:_vm._e(),(
        (!_vm.isViewerUser && !_vm.isLivetrackingUser) || _vm.isUserAdmin || _vm.isSupportUser
      )?[_c('v-list-item',{attrs:{"to":{ name: 'Geofences', query: _vm.routeQuery }}},[_c('v-tooltip',{attrs:{"disabled":!_vm.miniVariant,"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-list-item-icon',_vm._g({class:[_vm.miniVariant ? 'mx-2' : 'mr-2']},on),[_c('v-icon',[_vm._v(" pentagon ")])],1),_c('v-list-item-content',[(!_vm.miniVariant)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('geofences.Geofences')))]):_vm._e()],1)]}}],null,false,4027037994)},[_c('span',[_vm._v(_vm._s(_vm.$t('geofences.Geofences')))])])],1)]:_vm._e(),(
        (!_vm.isViewerUser && !_vm.isLivetrackingUser) || _vm.isUserAdmin || _vm.isSupportUser
      )?[_c('v-list-item',{attrs:{"to":{ name: 'Documents', query: _vm.routeQuery }}},[_c('v-tooltip',{attrs:{"disabled":!_vm.miniVariant,"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-list-item-icon',_vm._g({class:[_vm.miniVariant ? 'mx-2' : 'mr-2']},on),[_c('v-icon',[_vm._v(" description ")])],1),_c('v-list-item-content',[(!_vm.miniVariant)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('documents.Documents')))]):_vm._e()],1)]}}],null,false,3610900228)},[_c('span',[_vm._v(_vm._s(_vm.$t('documents.Documents')))])])],1)]:_vm._e(),(
        (!_vm.isViewerUser && !_vm.isLivetrackingUser) || _vm.isUserAdmin || _vm.isSupportUser
      )?[(_vm.miniVariant)?_c('v-menu',{attrs:{"z-index":"9999","offset-x":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var menu = ref.on;
      var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var tooltip = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-account-multiple")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('administration.Administration')))])])]}}],null,false,4007600490)},[_c('DropdownMenu',{attrs:{"menu-items":_vm.administrationDropdown}})],1):_c('v-list-group',{on:{"click":function($event){return _vm.closeDropdown('administrationMenu')}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v(" mdi-account-multiple ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('administration.Administration')))])],1)]},proxy:true}],null,false,126866755),model:{value:(_vm.administrationMenu),callback:function ($$v) {_vm.administrationMenu=$$v},expression:"administrationMenu"}},[_c('DropdownMenu',{attrs:{"menu-items":_vm.administrationDropdown}})],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }