import '@/installCompositionApi'
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueI18n from 'vue-i18n'
import { i18n, loadMessages } from './translations/i18n'

Vue.use(VueI18n)

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
Vue.use(Toast, {})

import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css' // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility'

import vuetify from './plugins/vuetify'

Vue.config.productionTip = true

async function initApp() {
  await loadMessages()
  new Vue({
    router,
    i18n,
    vuetify,
    render: function (h) {
      return h(App)
    }
  }).$mount('#app')
}
initApp()
