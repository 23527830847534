import { ref } from '@vue/composition-api'
import cloneDeep from 'lodash-es/cloneDeep'

const today = ref(new Date())
const yesterday = ref(new Date(today.value))

const dateRange = ref({
  start: new Date(
    new Date(yesterday.value.setDate(yesterday.value.getDate() - 1)).setHours(
      7,
      0,
      0
    )
  ).toISOString(),
  end: new Date(today.value.setHours(22, 0, 0)).toISOString()
})
const initialDateRange = cloneDeep(dateRange)
export default function startAndEndDateTime() {
  const setStartAndEndDateTime = value => {
    dateRange.value = value
  }
  const setStartAndEndDate = value => {
    let startHours = new Date(dateRange.value.start).getHours()
    let startMinutes = new Date(dateRange.value.start).getMinutes()
    let endHours = new Date(dateRange.value.end).getHours()
    let endMinutes = new Date(dateRange.value.end).getMinutes()
    dateRange.value.start = new Date(value[0]).setHours(
      startHours,
      startMinutes
    )
    dateRange.value.end = new Date(value[1]).setHours(endHours, endMinutes)
  }
  const setStartTime = value => {
    if (value) {
      let time = value.split(':')
      let hours = time[0]
      let minutes = time[1]
      let date = new Date(dateRange.value.start)
      dateRange.value.start = date.setHours(hours, minutes)
    }
  }
  const setEndTime = value => {
    if (value) {
      let time = value.split(':')
      let hours = time[0]
      let minutes = time[1]
      let date = new Date(dateRange.value.end)
      dateRange.value.end = date.setHours(hours, minutes)
    }
  }
  return {
    dateRange,
    initialDateRange,
    setStartAndEndDateTime,
    setStartAndEndDate,
    setStartTime,
    setEndTime
  }
}
