import { i18n } from '@/translations/i18n'
import { computed } from '@vue/composition-api'
import tablePaging from '@/components/tablePaging'
import startAndEndDateTime from '@/components/use/startAndEndDateTime'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
import { TokenService } from '@/services/storage.service.js'
const { routeQuery } = tablePaging()
const { initialDateRange } = startAndEndDateTime()
const { getCompanySettings, getUserRoles, getChildCompanyFeatures } =
  getCompanySettingsFromToken()
export default function menuDropdowns() {
  const administrationDropdown = computed(() => {
    return [
      {
        name: i18n.t('global.Assets'),
        to: {
          name: 'VehiclesAndAssets',
          query: { ...routeQuery.value }
        },
        enabled: true
      },
      {
        name: i18n.t('devices.Devices'),
        to: { name: 'Devices', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('global.Groups'),
        to: { name: 'VehicleAndAssetGroups', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('users.Users'),
        to: { name: 'Users', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('userGroups.UserGroups'),
        to: { name: 'UserGroups', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('customers.Customers'),
        to: { name: 'AllCustomers', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('drivers.Drivers'),
        to: { name: 'Drivers', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('annualLeave.AnnualLeave'),
        to: { name: 'AnnualLeave', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('assetAttributes.AssetAttributes'),
        to: { name: 'AssetAttributes', query: routeQuery.value },
        enabled: true
      }
    ]
  })
  const companyFeature = TokenService.getChildCompanyFeatures()
    ? TokenService.getChildCompanyFeatures()
    : TokenService.getCompanyFeatures()

  const reportsDropdown = computed(() => {
    return [
      {
        name: i18n.t('menu.RetentionTime'),
        to: {
          name: 'ReportsByRetentionTimeAtBuyers',
          query: {
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString(),
            term: ''
          }
        },
        enabled: companyFeature.retentionTimeAtBuyerReport
      },
      {
        name: i18n.t('milageReport.milageReport'),
        to: {
          name: 'milageReport',
          query: {
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString(),
            term: ''
          }
        },
        enabled: companyFeature.mileageReport
      },
      {
        name: i18n.t('geoEntryExitTimes.GeoEntryExitTimes'),
        to: {
          name: 'GeofencesEntryExitTimes',
          query: {
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString(),
            term: ''
          }
        },
        enabled: companyFeature.geoFenceEntryExitReport
      },
      {
        name: i18n.t('deviceActivityReport.DeviceActivityReport'),
        to: {
          name: 'DeviceActivityReport',
          query: {
            term: ''
          }
        },
        enabled:
          (companyFeature.deviceActivityReport && isUserAdmin()) ||
          (companyFeature.deviceActivityReport && isSupportUser())
      },
      {
        name: i18n.t('mileageInOut.MileageInOut'),
        to: {
          name: 'MileageInOutReports',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled: companyFeature.mileageInOutReport
      },
      {
        name: i18n.t('assetLocation.assetLocation'),
        to: {
          name: 'AssetLocationReport',
          query: {
            ...routeQuery.value
          }
        },
        enabled: companyFeature.assetLocationReport
      },
      {
        name: i18n.t('geoHistory.geoHistory'),
        to: {
          name: 'GeoHistoryReport',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled: companyFeature.geoHistoryReport
      },
      {
        name: i18n.t('menu.GeoServedContainers'),
        to: {
          name: 'GeofencesAndServedContainers',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled:
          !getChildCompanyFeatures.value &&
          getCompanySettings.value?.companyId === '7'
      }
    ]
  })

  const historyDropdown = computed(() => {
    return [
      {
        name: i18n.t('menu.AssetHistory'),
        to: {
          name: 'VehicleAndAssetHistory',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString(),
            limit: 25
          }
        },
        enabled: companyFeature.basicTracking
      },
      {
        name: i18n.t('global.WaybillProgress'),
        to: {
          name: 'WaybillProgress',
          query: {
            ...routeQuery.value,
            from: new Date(
              new Date().setUTCHours('00', '00', '00')
            ).toISOString(),
            to: new Date(
              new Date().setUTCHours('23', '59', '59', '999')
            ).toISOString(),
            limit: 25
          }
        },
        enabled: companyFeature.wayBillProgress
      }
    ]
  })
  const supportPannelDropdown = computed(() => {
    return [
      {
        name: i18n.t('supportPannel.ManageCompanies'),
        to: { name: 'SupportPannelManageCompanies', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('systemMessages.SystemMessages'),
        to: {
          name: 'SystemMessages',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled: true
      },
      {
        name: i18n.t('supportPannel.SwitchCompanies'),
        to: '/SupportPannel/SwitchCompanies/',
        enabled: true
      },
      {
        name: i18n.t('supportPannel.devices.ManageDevices'),
        to: { name: 'SupportPannelDevices', query: routeQuery.value },
        enabled: true
      },
      {
        name: i18n.t('devices.BulkCreationOfContainers'),
        to: {
          name: 'BulkCreationOfContainers'
        },
        enabled: true
      }
    ]
  })
  const waybillsDropdown = computed(() => {
    return [
      {
        name: i18n.t('global.WayBills'),
        to: {
          name: 'WayBills',
          query: {
            ...routeQuery.value,
            from: new Date(initialDateRange.value.start).toISOString(),
            to: new Date(initialDateRange.value.end).toISOString()
          }
        },
        enabled: true
      },
      {
        name: i18n.t('wayBills.AddWayBill'),
        to: '/WayBills/AddWayBill',
        enabled: true
      },
      {
        name: i18n.t('menu.ImportWayBills'),
        to: '/WayBills/Import',
        enabled: true
      }
    ]
  })
  const isUserAdmin = () => {
    return getUserRoles.value?.includes('Admin')
  }
  const isSupportUser = () => {
    return getUserRoles.value?.includes('Support')
  }
  return {
    administrationDropdown,
    reportsDropdown,
    historyDropdown,
    waybillsDropdown,
    supportPannelDropdown,
    isUserAdmin,
    routeQuery
  }
}
