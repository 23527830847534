import { ref } from '@vue/composition-api'

const getGoogleMapsApi = ref(null)

export default function useGoogleMapsApi() {
  const setGoogleMapsLoader = data => {
    getGoogleMapsApi.value = data
  }

  return {
    getGoogleMapsApi,
    setGoogleMapsLoader
  }
}
