import { i18n } from '@/translations/i18n'
import { getAssetStateValue } from '@/components/Livetracking/helpers/assetsMethods'

export const toolTip = asset => {
  return `<div class="pt-3 pl-3 pr-3"><div class=" d-flex justify-center">
      <span class="font-weight-bold"> ${asset.name}  
         ${asset.plateNumber}</span> </div>
        </div>
        <div class="pa-3 d-flex justify-center">  ${driverName(asset)}</div>
        <div class=" d-flex justify-center align-center px-3 pb-3">
        <img width="25" class="mr-3" src="${assetStatusImg(asset)}"/>  
        <span class="font-weight-black mr-2">
        <span class="font-weight-light"> ${getAssetStateValue(asset)} </span>
        <p class="font-weight-light ma-0 red--text">${noLocation(asset)}</p>
      </div>
    </div>`
}
const driverName = asset => {
  if (asset.driverName) {
    return `<span class="mdi mdi-account mr-1"></span> 
        ${asset.driverName}`
  } else {
    return ''
  }
}
const noLocation = asset => {
  return `${
    String(asset.latitude).charAt(0) === '0' ||
    String(asset.longitude).charAt(0) === '0'
      ? i18n.t('assetsFilters.NoLocationDesc')
      : ''
  }`
}
export const popUp = assetsInCluster => {
  let assetsStates = assetState(assetsInCluster)
  return `<div class="v-list pt-0 v-sheet theme--light ml-1">
<div class="v-list-item theme--light text-subtitle-2">
  ${assetsInCluster.length} ${i18n.t('global.Assets')}
</div>
<div class="v-list-item theme--light">
  <img width="25" class="mr-3" src="${require('@/images/icons/idle-status.svg')}" />
  <span class="font-weight-black mr-2">
  ${assetsStates.noState}</span>
  <span class="font-weight-light"> No State </span>
</div>
<div class="v-list-item theme--light">
  <img width="25" class="mr-3" src="${require('@/images/icons/idle-status.svg')}" />
  <span class="font-weight-black mr-2">
  ${assetsStates.onBattery}</span>
  <span class="font-weight-light"> On Battery </span>
</div>
<div class="v-list-item theme--light">
  <img width="25" class="mr-3" src="${require('@/images/icons/idle-status.svg')}" />
  <span class="font-weight-black mr-2">
  ${assetsStates.sleeping}</span>
  <span class="font-weight-light"> Sleeping </span>
</div>
<div class="v-list-item theme--light">
  <img width="25" class="mr-3" src="${require('@/images/icons/parking-status.svg')}" />
<span class="font-weight-black mr-2">  
${assetsStates.parking} </span>
<span class="font-weight-light"> ${i18n.t('assetsFilters.Parking')} </span>
</div>
<div class="v-list-item theme--light">
  <img width="25" class="mr-3" src="${require('@/images/icons/idle-status.svg')}" />
<span class="font-weight-black mr-2">  
${assetsStates.standBy} </span>
<span class="font-weight-light"> Stand by </span>
</div>
<div class="v-list-item theme--light">
  <img width="25" class="mr-3" src="${require('@/images/icons/driving-status.svg')}" />
  <span class="font-weight-black mr-2">
  ${assetsStates.driving}</span>
  <span class="font-weight-light"> ${i18n.t('global.Driving')} </span>
</div>
<div class="v-list-item theme--light">
  <img width="25" class="mr-3" src="${require('@/images/icons/idle-status.svg')}" />
 <span class="font-weight-black mr-2"> 
 ${assetsStates.idling}</span>
 <span class="font-weight-light"> ${i18n.t('global.Idle')} </span>
</div>
</div>`
}
const assetStatusImg = asset => {
  if (asset.state === 5) {
    return require('@/images/icons/driving-status.svg')
  } else if (asset.state === 3) {
    return require('@/images/icons/parking-status.svg')
  } else {
    return require('@/images/icons/idle-status.svg')
  }
}
const assetState = assets => {
  return assets.reduce(
    (acc, asset) => {
      if (asset.options) {
        if (asset.options.currentState.state === 5) {
          acc.driving++
        } else if (asset.options.currentState.state === 3) {
          acc.parking++
        } else if (asset.options.currentState.state === 0) {
          acc.noState++
        } else if (asset.options.currentState.state === 1) {
          acc.onBattery++
        } else if (asset.options.currentState.state === 2) {
          acc.sleeping++
        } else if (asset.options.currentState.state === 4) {
          acc.standBy++
        } else if (asset.options.currentState.state === 6) {
          acc.idling++
        }
      } else {
        if (asset.currentState.state === 5) {
          acc.driving++
        } else if (asset.currentState.state === 3) {
          acc.parking++
        } else if (asset.currentState.state === 0) {
          acc.noState++
        } else if (asset.currentState.state === 1) {
          acc.onBattery++
        } else if (asset.currentState.state === 2) {
          acc.sleeping++
        } else if (asset.currentState.state === 4) {
          acc.standBy++
        } else if (asset.currentState.state === 6) {
          acc.idling++
        }
      }
      return acc
    },
    {
      noState: 0,
      onBattery: 0,
      sleeping: 0,
      standBy: 0,
      driving: 0,
      idling: 0,
      parking: 0
    }
  )
}
